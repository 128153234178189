import { Card, PostsCardFeed } from "@/components/Card";
import { PostsCardFeedV2 } from "@/components/Card/PostsCardFeed/PostsCardFeedV2";
import { LoadingScreen } from "@/components/Loading";
import { NotFound } from "@/components/Not-Found";
import { Box } from "@/components/styled";
import { MentionType, PostStatus } from "@/gql/graphql";
import { useFetchForYouFeed } from "@/hooks/useFetchForYouFeed";
import { ApolloError } from "@apollo/client";

export const ForYou: React.FC = ({}) => {
  const { posts, error, loadMore, loading } = useFetchForYouFeed();

  if (error) {
    <NotFound />;
  }

  return (
    <PostsCardFeedV2
      // @ts-ignore
      data={posts}
      loading={loading}
      loadMore={loadMore}
    />
  );
};
